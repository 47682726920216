<template>
  <div>
    <div v-if="selectNFT"
         class="nft-card md-layout">
      <div class="nft-card-img md-layout-item md-size-50 md-small-size-100">
        <img :src="selectNFT.image"
             alt="img-blur-shadow"
             class="shadow img-fluid border-radius-xl"/>
      </div>
      <div class="nft-card-content md-layout-item md-size-50 md-small-size-100 mr-auto">
        <div class="nft-card-title">
          <strong class="nft-card-name">{{ selectNFT.name }}</strong>
          <a :href="selectNFT.scopeLink" v-if="selectNFT.contractAddress !== ''"  class="nft-card-wallet-addr"> Contract Address: {{selectNFT.contractAddress}}</a>
        </div>
        <div v-if="selectNFT.ranking !== ''"
             class="nft-card-count-wrap">
          <div class="nft-card-count">
            <div class="nft-card-size-50">
              <span class="nft-card-count-title">Ranking</span>
              <CountTo class="ranking" :start-val="0" :end-val="selectNFT.ranking" :duration="1000" :separator="`,`" />
            </div>
            <div class="nft-card-size-50">
              <span class="nft-card-count-title">Score</span>
              <CountTo class="ranking" :start-val="0" :end-val="selectNFT.score" :duration="1000" :separator="`,`" />
            </div>
          </div>
        </div>
        <ul class="nft-card-attr-wrap md-layout">
          <li v-for="(attribute, index) in selectNFT.attributes"
              :key="index"
              class="nft-card-attr-item md-layout-item md-size-33 list-unstyled">
            <div class="nft-card-attr-item-wrap">
              <span class="nft-card-attr-item-type">{{attribute.trait_type.toUpperCase()}}</span><br>
              <span class="nft-card-attr-item-value">{{attribute.value.toUpperCase()}}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import CountTo from "vue-count-to/src";

  export default {
    props: {
      selectNFT: {
        type: Object,
        description: 'container name',
      },
    },
    components: {
      CountTo,
    },
  };
</script>

<style lang="scss">
  .nft-card {
    margin: 0 10px;
    background-color: #2f2b5a;
    font-stretch: normal;
    font-style: normal;
    border-radius: 10px;


    .nft-card-img {
      display: flex;
      align-items: center;
      padding: 15px;

      img {
        width: 100%;
        border-radius: 10px;
      }
    }

    .nft-card-content {
      padding: 15px;

      .nft-card-title {
        border-bottom: 1px solid #9d5bff;
        padding: 10px;
        margin: 0 5px 10px;

        .nft-card-name {
          color: #fff;
          font-size: 36px;
          line-height: 47px;
          font-weight: 800;
        }
        .nft-card-wallet-addr {
          display: block;
          color: #9d5bff;
          font-size: 12px;
          line-height: 25px;
          font-weight: 400;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .nft-card-count-wrap {
        border-bottom: 1px solid #9d5bff;
        margin: 0 5px 10px;
        padding: 10px 0;

        .nft-card-count {

          overflow: hidden;
          .nft-card-size-50 {
            width: 50%;
            float: left;
          }

          span {
            display: block;
            padding-left: 10px;
            color: #fff;
            font-size: 17px;
            font-weight: 700;
            line-height: 1.41em;
          }

          .ranking,
          .Score {
            color: #9d5bff;
            font-size: 36px;
            line-height: 47px;
            font-weight: 800;
          }
        }
      }

      .nft-card-attr-wrap {
        margin: 0;
        padding: 20px 0 0;



        .nft-card-attr-item {
          padding: 0 5px;
          margin: 0 0 10px;

          .nft-card-attr-item-wrap {
            padding: 20px 0;
            border-radius: 5px;
            border: 1px solid #9d5bff;
            text-align: center;

            font-size: 16px;
            font-weight: 800;
            line-height: 1.41em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .nft-card-attr-item-type {
              color: #9d5bff;

            }
            .nft-card-attr-item-value {
              color: #fff;
            }
          }

        }

      }
    }
  }

</style>