<template>
  <div class="wrapper">


    <br><br><br>
    <div class="section">
      <div class="container">
        <NFTViewer :selectNFT="publicNFTDetail"/>
        <template v-if="publicNFTDetail.availability === 'Available'">
          <div class="ac-button-wrap">
            <md-button class="ac-nft-buy-btn ac-button md-round"
                       @click="onBuy(publicNFTDetail.marketLink)">
              <img style="width: 22px; vertical-align: top; padding-right: 3px;"  :src="icon_wemix" alt="wemix"/>
              <strong class="text-price">{{wemix.utils().fromWei(publicNFTDetail.price)}}</strong>
              <strong class="text-buy">{{language.detail_card_buy_btn}}</strong>
            </md-button>
          </div>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import {
    // ProductCard,
    Modal,
  } from "@/components";
  // import { LoginCard, PricingCard, Modal } from "@/components";
  import Mixins from "@/plugins/basicMixins";
  import NFTViewer from "@/pages/nft/sub/NFTViewer";

  export default {
    bodyClass: "ac-nft-detail",
    components: {
      // ProductCard,
      NFTViewer,
    },
    mixins: [Mixins.HeaderImage],
    computed: {
      ...mapGetters([
        "publicNFTDetail",
        "language",
        "wemix",
      ]),
    },
    watch: {
      $route() {
        this.GET_PUBLIC_NFT_DETAIL_DATA(this.$route.params);
      },
    },
    mounted() {
      this.GET_PUBLIC_NFT_DETAIL_DATA(this.$route.params);
    },
    methods: {
      ...mapActions([
        'GET_PUBLIC_NFT_DETAIL_DATA',
      ]),
      onBuy(marketLink) {
        window.open(marketLink);
      },
    },
    data() {
      return {
        icon_wemix: require("@/assets/img/logos/WEMIX_Symbol.png"),

        isShowDetailNFTModal: false,
        activeNames: ['availability'],
        subscribe: null,
      };
    },
  }
</script>

<style lang="scss">



  .ac-nft-detail {
    color: white;

    .wrapper {
      background-image: url("../../assets/img/pages/nft/03_nft_bg3.png");
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: cover;


    }

    .ac-button-wrap {
      padding: 40px 0 130px;
      text-align: center;

      .text-price {
        display: inline-block;
        padding-right: 20px;
        font-size: 20px;
        color: darkred;
      }

      .ac-nft-buy-btn {
        background-color: #fff !important;
        display: inline-block;
        outline: none;
        text-align: center;
        text-decoration: none;
        color: black !important;
        font-size: 22px;
        font-weight: 700;
        -webkit-border-radius: 5rem;
        -moz-border-radius: 5rem;
        border-radius: 5rem;
        -webkit-box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
        box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);

        .md-ripple {
          padding: 15px 30px !important;
        }

        img {
          width: 34px;
          padding-right: 10px;
        }
      }

      .button-wrap {
        padding: 80px 0 130px;
        text-align: center;


      }
    }





    .modal-minted-result {
      max-width: 900px;
      /*background-color: #f00;*/
      border-radius: 20px;

      .card-minted-result {
        border-radius: 20px;
      }

      .md-card-content {
        padding: 0;
        background-color: #2e2c58;
        border-radius: 20px;

        img {
          /*-moz-border-radius-topleft: 20px;*/
          /*-moz-border-radius-bottomleft: 20px;*/
          /*border-radius: 20px;*/
          border-top-left-radius: 20px;
          /*border-top-right-radius: 20px;*/
          border-bottom-left-radius: 20px;
          /*border-bottom-left-radius: 20px;*/
        }
      }
    }

  }
</style>